import axios from 'axios';
import Cookie from 'js-cookie';

import { ADD_ATTENDANCE_REQUEST, ADD_ATTENDANCE_SUCCESS, ADD_ATTENDANCE_FAIL, ADD_MANUAL_REQUEST, ADD_MANUAL_SUCCESS, ADD_MANUAL_FAIL, CONFIRM_MANUAL_REQUEST, CONFIRM_MANUAL_SUCCESS, CONFIRM_MANUAL_FAIL } from '../types/scannerTypes';

export const addToAttendance = (qrData, table = '') => async dispatch => {
    try {
        dispatch({ type: ADD_ATTENDANCE_REQUEST });

        const qr = JSON.parse(qrData);

        if (qr?.timestamp) {
            if (qr?.timestamp >= (Date.now() - (15 * 60 * 1000))) {
                const { data } = await axios.post('/app_handler.php', { secret_code: qr?.secret_code, customer_id: qr?.customer_id, table }, { headers: { 'WG-Method': 'VALIDATE_QR', "WG-Key": Cookie.get('accessToken') }});
                
                dispatch({ type: ADD_ATTENDANCE_SUCCESS, payload: data.customer });
            } else {
                dispatch({ type: ADD_ATTENDANCE_FAIL, payload: 'QR Code has expired. Please refresh the page to generate a new one.' });
            }
        } else {
            dispatch({ type: ADD_ATTENDANCE_FAIL, payload: 'Error with QR Code. Please reload the app.' });
        }

    } catch (error) {
        dispatch({ type: ADD_ATTENDANCE_FAIL, payload: error?.response?.data?.error });
    }
}

export const addManualScan = (customer) => async dispatch => {
    try {
        dispatch({ type: ADD_MANUAL_REQUEST });
        
        const { data } = await axios.post('/app_handler.php', { customer }, { headers: { 'WG-Method': 'VALIDATE_MANUAL', "WG-Key": Cookie.get('accessToken') }});
        
        dispatch({ type: ADD_MANUAL_SUCCESS, payload: data.customer });
    } catch (error) {
        dispatch({ type: ADD_MANUAL_FAIL, payload: error?.response?.data?.error });
    }
};

export const addManualScanConfirm = (customerId, table = '') => async dispatch => {
    try {
        dispatch({ type: CONFIRM_MANUAL_REQUEST });
        
        const { data } = await axios.post('/app_handler.php', { customer_id: customerId, table }, { headers: { 'WG-Method': 'VALIDATE_MANUAL_CONFIRM', "WG-Key": Cookie.get('accessToken') }});
        
        dispatch({ type: CONFIRM_MANUAL_SUCCESS, payload: data.message });
    } catch (error) {
        dispatch({ type: CONFIRM_MANUAL_FAIL, payload: error?.response?.data?.error });
    }
};